import React from 'react';
import Layout from '@components/Layout/Layout';
import TextHero from '@components/TextHero';
import Grid, { Row } from '@components/Grid';
import Card, { CardImage } from '@components/Card';
import Container from '@components/Container';
import BottomCta from '@components/Home/BottomCta';
import { graphql } from 'gatsby';

import {
  SparaQuery,
  ContentfulCard,
  ContentfulDialogContent,
  ContentfulTextList,
} from '../../graphql-types';

import SEO from '@components/seo';
import useDialogRoute from '@components/Dialog/useDialogRoute.hook';
import appenPages from '@src/data/appenPages';
import ContentfulDialog from '@src/components/Dialog/ContentfulDialog';
import useContentfulImage from '@src/hooks/useContentfulImage.hook';
import useVaultTiers from '@src/hooks/useVaultTiers.hook';
import useAppPackages from '@src/hooks/useAppPackages.hook';
import ProductHero from '@src/components/ProductHero';
import SnapScrollContainer from '@src/components/SnapScrollContainer';
import Footer from '@src/components/Footer/Footer';
import * as styles from './spara.module.scss';
import StickyFooter from '@src/components/StickyFooter/StickyFooter';
import AppRatings from '@src/components/Home/AppRatings';
import useIsMobile from '../hooks/useIsMobile.hook';
import DownloadAppButton from '@src/components/DownloadAppButton';
import UspList, { UspListItem } from '@src/components/UspList/UspList';
import Badge from '@src/components/Badge/Badge';

type SparaProps = {
  data: SparaQuery;
};

function Spara({ data }: SparaProps) {
  const {
    contentfulPage: {
      headLine,
      pageHeader,
      pageHeaderDescription: { pageHeaderDescription },
      heroImage,
      pageContent,
    },
  } = data;

  const interestDialog = useDialogRoute('ranta');
  const interestCard = pageContent[0] as ContentfulCard;
  const interestDialogContent = pageContent[1] as ContentfulDialogContent;
  const interestImg = useContentfulImage(interestCard.backgroundImage);
  const packageInfo = useAppPackages();
  //const interestCardImg = useContentfulImage(interestCard.backgroundImage);
  //interestCardImg.objectPosition = '50% 13%';
  //
  const saveCard = pageContent[2] as ContentfulCard;
  const saveCardImg = useContentfulImage(saveCard.backgroundImage);
  const saveDialog = useDialogRoute('spara');
  const saveDialogContent = pageContent[3] as ContentfulDialogContent;

  const touchDialog = useDialogRoute('touch');
  const touchDialogContent = pageContent[4] as ContentfulDialogContent;

  const heroCard = pageContent[5] as ContentfulCard;
  const uspList = (pageContent[7] as ContentfulTextList).advancedTextListItems;

  const tiers = useVaultTiers();
  const savingsInterestTitle = `Upp till ${packageInfo.maxInterest} ränta`;
  const heroSubtitle = `Upp till ${packageInfo.maxInterest}.`;
  const heroImg = useContentfulImage(heroImage);

  const isMobile = useIsMobile();

  return (
    <SnapScrollContainer>
      <Layout theme="white" subNavLinks={appenPages} customFooter={<></>}>
        <SEO title={headLine} description={pageHeaderDescription} />
        <Container>
          <ProductHero
            title={pageHeader}
            subtitle={packageInfo.maxInterest && heroSubtitle}
            image={heroImg}
          >
            <UspList items={uspList as UspListItem[]} />
            <DownloadAppButton />
            {!isMobile && <AppRatings />}
          </ProductHero>
          <Grid spacedRows>
            <Row>
              <Card
                title={heroCard.title}
                text={heroCard.subtitle}
                badge={<Badge color="purple-5">Rekordränta!</Badge>}
                contentfulImg={useContentfulImage(heroCard.backgroundImage)}
                theme="purple-light"
                ctaElement={
                  <DownloadAppButton buttonText={heroCard.linkText} />
                }
                wide
                snap={true}
              />
            </Row>
            <Card
              title={interestCard.title}
              text={interestCard.subtitle}
              cta={packageInfo.maxInterest && savingsInterestTitle}
              onClick={interestDialog.openDialog}
              theme="soft"
              snap={true}
            >
              <CardImage imageData={interestImg} />
            </Card>
            <Card
              title={saveCard.title}
              text={saveCard.subtitle}
              cta={saveCard.linkText}
              backdropImage={saveCardImg}
              onClick={saveDialog.openDialog}
              theme="purple"
              snap={true}
            />
          </Grid>
        </Container>
        <div className={styles.bottomContainer}>
          <BottomCta />
          <Footer />
        </div>
        <ContentfulDialog
          contentfulData={interestDialogContent}
          visible={interestDialog.isOpen}
          onClose={interestDialog.closeDialog}
        />

        <ContentfulDialog
          contentfulData={saveDialogContent}
          visible={saveDialog.isOpen}
          onClose={saveDialog.closeDialog}
          uspList={tiers.list.map(t => ({
            icon: 'Check',
            text: `Sparvalv ${t.months} mån - ${t.interest} sparränta`,
          }))}
        />
        <ContentfulDialog
          contentfulData={touchDialogContent}
          visible={touchDialog.isOpen}
          onClose={touchDialog.closeDialog}
        />

        <StickyFooter />
      </Layout>
    </SnapScrollContainer>
  );
}

export default Spara;

export const query = graphql`
  query Spara($locale: String) {
    contentfulPage(pageUrl: { eq: "/spara2.0" }, node_locale: { eq: $locale }) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }
      heroImage {
        title
        gatsbyImageData(width: 1040)
      }
      pageContent {
        ... on ContentfulCardsSectionComponent {
          id
          entryTitle
          card {
            title
            subtitle
            linkPath
            linkText
          }
        }
        ... on ContentfulTextList {
          listName
          advancedTextListItems {
            icon
            text
          }
        }
        ... on ContentfulCard {
          id
          title
          subtitle
          backgroundImage {
            gatsbyImageData(width: 1040)
            title
          }
          linkText
          linkPath
        }
        ... on ContentfulDialogContent {
          id
          name
          textContent {
            textContent
          }
          questions {
            answer {
              answer
            }
            question
          }
          heading
          headerImage {
            title
            gatsbyImageData(width: 630)
          }
          ctaHref
          ctaText
          extraContent {
            ... on ContentfulTextList {
              id
              listName
              advancedTextListItems {
                icon
                text
              }
            }
            ... on ContentfulFaqSection {
              id
              questions {
                answer {
                  answer
                }
                question
              }
              title
              contactEmail
            }
          }
        }
      }
    }
  }
`;

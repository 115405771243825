import { numberToPercentageString } from '@src/lib/utils';
import { useEffect, useState } from 'react';

type VaultInfo = {
  name: string;
  interest: string;
  months: number;
};

type VaultTiers = {
  list: VaultInfo[];
};

let cached: VaultTiers = null;

const useVaultTiers = (): VaultTiers => {
  const year = {
    name: 'Rocker tidsbundet - 1 år',
    interest: '',
    months: 12,
  };
  const half = {
    name: 'Rocker tidsbundet - 6 månader',
    interest: '',
    months: 6,
  };
  const quarter = {
    name: 'Rocker tidsbundet - 3 månader',
    interest: '',
    months: 3,
  };

  const [data, setData] = useState({
    list: [year, half, quarter],
  });

  useEffect(() => {
    if (cached) {
      setData(cached);
      return;
    }

    const createTier = tier => {
      const {
        product: {
          interestRateLadder: { ranges },
          name,
        },
      } = tier;

      const lowestRange = ranges[0];

      return {
        name,
        interest: numberToPercentageString(lowestRange.interestRate),
      };
    };

    const fetchData = async () => {
      const url = '//savings.rocker.com/products/vaults';
      const res = await fetch(url);
      const tiers = await res.json();

      const list = tiers.map(t => ({
        name: t.name,
        months: (t.durationDay || 0) / 30 + (t.durationYear || 0) * 12,
        interest: numberToPercentageString(t.interestRate),
      }));

      list.sort((a, b) => {
        if (a.months < b.months) return -1;
        if (a.months > b.months) return -1;
        return 0;
      });

      const data = {
        list,
      };

      cached = data;

      setData(data);
    };

    fetchData();
  }, []);

  return data;
};

export default useVaultTiers;
